<template>
  <div class="auxiliaryResources dataM">
    <el-table 
      :data="tableList" 
      header-cell-class-name="tableHeader" 
      style="width: 100%" 
      @selection-change="handleSelectionChange"
      @row-dblclick="handleEdit"
      >
      <el-table-column type="selection" width="55"/>
      <el-table-column label="序号" type="index" width="100">
        <template slot-scope="scope">
          <span>{{((page-1)*10+scope.$index+1) < 10 ? `0${(page-1)*10+scope.$index+1}` : (page-1)*10+scope.$index+1}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="resourcesStatus" label="状态">
        <template slot="header">
          <el-dropdown>
            <span class="el-dropdown-link">
              状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="changeStatus(0)">全部</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(1)">确认</el-dropdown-item>
              <el-dropdown-item @click.native="changeStatus(2)">未确认</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.resourcesStatus == 1"  class="greenStatus">确认</span>
          <span  class="redStatus" v-else>未确认</span>
        </template>
      </el-table-column>
      <el-table-column prop="resourceCode" label="编号"/>
      <el-table-column prop="resourceName" label="名称"/>
      <el-table-column prop="quantityUnit" label="单位"/>
      <el-table-column prop="totalQuantity" label="总数量"/>
      <el-table-column prop="availableQuantity" label="可用数量"/>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <div class="operation">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px" @click="handleEdit(scope.row)"/>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <auxiliaryResourcesDialog :createVisible.sync="createVisible" :currentData="currentData" @handleSearch="handleSearch"/>
  </div>
</template>

<script>
const auxiliaryResourcesDialog = () => import('../dialog/auxiliaryResourcesDialog.vue')
export default {
  name:"auxiliaryResources",
  components:{
    auxiliaryResourcesDialog
  },
  props:{
    tableList:{
      require:true,
      default:[],
    },
    statusType:{
      require:true
    },
    page:{
      default:1
    }
  },
  data(){
    return{
      createVisible:false,
      currentData:{}
    }
  },
  methods:{
    handleSelectionChange(val){
      let multipleSelection = val.map(row => row.id);
      this.$emit('update:multipleSelection',multipleSelection);
    },
    comfirm(row){
      this.$emit('comfirm',row)
    },
    changeStatus(val){
      this.$emit('update:statusType',val)
    },
    handleEdit(row){
      this.currentData = row
      this.createVisible = true
    },
    handleSearch(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss">
.auxiliaryResources{
  margin-top: 20px;
  .operation{
    font-size: 16px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  img{
    margin: 0 15px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
</style>